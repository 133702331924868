<template>
  <div>
    <h3 class="text-center my-4">
      FAQ´S
    </h3>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1" v-for=" (question,index) in questionList " :key="index">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="'accordion-'+ index" variant="light text-left" v-text="question.question">
          </b-button>
        </b-card-header>
        <b-collapse :id="'accordion-' + index " visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text v-html=" question.anwser"></b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        questionList: [{
            question: 'Who are Ecart customers?',
            anwser: 'Ecart is designed for everyone, we connect with customers all around the world.',
          },
          {
            question: 'For how long can I be a seller?',
            anwser: 'You can be a seller as long as you want. There is no limit time and you will always have the option to continue selling through Ecart.',
          },
          {
            question: 'What countries do you support? Can I become a seller if I sell in the USA, Mexico, or Colombia?',
            anwser: 'Yes. The program is open to sellers worldwide, using different shipping options and different shipping carriers.',
          },
          {
            question: 'What fees apply for selling in Ecart?',
            anwser: 'Listing your products on Ecart is free. Then, every time you receive an order, you will be charged a percentage of the product value. The percentage is established by every seller. The minimum fee is 8.5%.',
          },
          {
            question: 'How do I receive payment for my Ecart sales?',
            anwser: 'Payments are released 7 days after the customer receives the items. You will receive the balance in your account, and it will be available for you at any time. All payments to Ecart sellers are made through PayPal, Debit Card or Wire Transfer (fees may vary depending on your bank or processing entity). You can also check your account balance any time in your Dashboard > Payments section. ',
          },
          {
            question: 'How do I change my bank account information?',
            anwser: 'You can update your bank account information from Dashboard > Payments > Allowed Methods section.',
          },
          {
            question: 'Who do I contact for general support issues with Ecart?',
            anwser: 'For general application support regarding usability or troubleshooting, contact support@ecart.com',
          },
          {
            question: 'Is there a limit to the number of SKUs I can list on Ecart?',
            anwser: 'There are no limits, you can list as many products as you want.',
          },
          {
            question: 'I have a product that is OFFLINE and I don’t know why?',
            anwser: 'If a product is offline it can be due to a missing photo, price, or quantity. If you have all these options correctly and you still have a product OFFLINE contact support@ecart.com',
          },
          {
            question: 'How can I sell out of stock?',
            anwser: 'Go to Dashboard > Products > Product Information > Activate Sell Out of Stock option. You can also choose the Bulk Action of all products and select Sell Out of Stock.',
          },
          {
            question: 'Can the customer select the shipping option of his/her choice?',
            anwser: 'Yes, each product will have the shipping option you decide to provide. You can select the carries from Dashboard > Shipping section.',
          },
          {
            question: 'What are my options for shipping products?',
            anwser: 'There are 2 different shipping options: <br><br> Partner couriers: You will be able to send your products directly to the customers using our partner courier services. In this case, we take care of calculating shipping costs and taxes. <br><br> Your own logistics: Our platform allows you to use the logistics solution you prefer. You will be able to ship your products and adding the tracking number to the order.',
          },
          {
            question: 'I received an order for a product that is out of stock. What should I do?',
            anwser: 'You should cancel the order and we will refund the buyer. You can cancel the order directly from your Dashboard > Orders > Order Details > Cancel <br> *Note that canceling many orders might significantly impact your reputation.',
          },

          {
            question: 'Are there any restrictions I should take into account?',
            anwser: 'Yes, when you upload your products you should take into consideration the following restrictions: <br> Dimension and weight: The dimension and weight restrictions according to the carriers. <br> Restricted products: Each country has its own import regulations. Make sure you have all the documents required to export your products. ',
          },

          {
            question: 'What do I need to ship internationally?',
            anwser: 'Commercial Invoice (BOL) -Shipping Label-Order Summary <br> *For some products Export License (FDA) and Toxic Substance Control Act Certification is required. ',
          },
          {
            question: 'How can I get the BOL (Bill of Landing) for International Orders?',
            anwser: 'You are able to print your  BOL after generating your shipping label. Steps for updating are listed below: <br> Go to Dashboard > Orders <br> On Orders > Identify your International Order <br> Click on “Create Label” <br> Identify Bill of Landing in the related field <br> Complete the accurate information > UPDATE PRINT',
          },
          {
            question: 'What can I do when my sales are slow?',
            anwser: 'While sales will naturally fluctuate based on your inventory and the time of the year, there are always steps you can take to maximize success. Make sure your products are 100% improved, offer discounts on your products, and don’t forget to recommend Ecart as a unique shopping experience to your clients.',
          },
          {
            question: 'How can I change my store information?',
            anwser: 'You can change your store name as often as you would like. Steps for updating are listed below: <br> Go to Dashboard > Store <br> Store > Click on your store name Store Information > Complete the store information in the related field > UPDATE  ',
          },
          {
            question: 'Who is responsible for a package lost in transit?',
            anwser: 'Carriers are typically responsible for any loss or damage that occurs in transit. If you have sold an item and it goes missing on the way to its destination, we recommend that you can work with the chosen courier to locate it. If it becomes clear the package cannot be recovered, a refund will need to be issued to your customer. <br> If you have returned a purchase and it appears to be lost on its way, this will be handled differently depending on who purchased/provided the return shipping label. We recommend you contact support@ecart.com to have the specific details reviewed for appropriate next steps.',
          },
          {
            question: 'What happens with currency fluctuations?',
            anwser: 'You don’t need to worry about currency fluctuations. Although customers will pay in local currency, you will receive your balance depending on the Store Currency you selected, discounting applicable fees may apply.',
          },

        ]
      }
    }
  }
</script>

<style>

</style>